$(function(){
	$(window).click(function(ev){
		var trg = $(ev.target);
		if(trg.hasClass('pagination-share') || trg.parents('.pagination-share').length)
			$('.pagination-share-popup').toggleClass('show');
		else
			$('.pagination-share-popup').removeClass('show');
	});

	$('.pagination-share-popup').click(function(e) {
		e.preventDefault();
		e.stopPropagation();
	});
});
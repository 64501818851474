$(function(){
	$('.tab-switch').click(function(ev){
		ev.preventDefault();
		var trgText = $(this).data('target');
		var trg = $('#'+trgText);
		if(isMobile() && trg.hasClass('tab-on')){
			trg.removeClass('tab-on');
			$(this).removeClass('selected');
			trg.css('display','block');
			trg.slideUp( "slow",function(){ $(this).attr('style',''); } );
		}else{
			$('.tab').removeClass('tab-on');
			trg.addClass('tab-on');
			$('.tab-switch').removeClass('selected');
			$('.tab-switch').each(function(){
				if($(this).data('target')==trgText)
					$(this).addClass('selected');
			});
			if(isMobile()){
				trg.css('display','none');
				trg.slideDown( "slow",function(){ $(this).attr('style',''); } );
			}
		}
	});
	function redraw(){
		if(!isMobile() && !$('.tab-on').length){
			$('.tab-switch.selected').eq(0).click();
		}
	}
	$(window).resize(function() {
	  redraw();
	});

	if(isMobile()) {
		if(!$('.tab-switch.selected').length)
			$('.tab-switch').eq(0).addClass('selected');
	} else {
		var index = $('.tab-switch.selected').index();
		if(index < 0)
			$('.tab-switch').eq(0).click();
	}
});


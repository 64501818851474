$(document).ready(function(){
  $('.carousel-single-news-img').css('width',(function(){   // setting correct width for carousel
	var m=0;
	$('.carousel-single-news-img div img').each(function(){
		m = Math.max(m, $(this).width());
	});  
	return m;
  })()+'px');
  $('.carousel1').slick({
	dots: false,
	 infinite: true,
	slidesToShow: 2,
	slidesToScroll: 2,
	prevArrow: '<a href="#"><img src="/img/prev.svg" class="left-arrow"></a>',
	nextArrow: '<a href="#"><img src="/img/next.svg" class="right-arrow"></a>'
  });
  $('.carousel2,.carousel-news-row-img,.carousel-single-news-img').slick({
	dots: false,
	prevArrow: '<a href="#"><img src="/img/prev.svg" class="left-arrow"></a>',
	nextArrow: '<a href="#"><img src="/img/next.svg" class="right-arrow"></a>'
  });
  var logos = $('.logos-carousel').children().length;
  $('.brands-carousel,.logos-carousel').each(function(){ 
		$(this).html( $(this).html() + $(this).html() );
  });
  $('.brands-carousel').slick({
	dots: false,
	prevArrow: '<a href="#"><img src="/img/prev.svg" class="left-arrow"></a>',
	nextArrow: '<a href="#"><img src="/img/next.svg" class="right-arrow"></a>',
	asNavFor: '.logos-carousel',
    autoplay: true,
    autoplaySpeed: 5000,
  });
  $('.logos-carousel').slick({
	dots: false,
	arrows:false,
	infinite: true,
	slidesToShow: logos,
	slidesToScroll: 1,
	asNavFor: '.brands-carousel',
  });
  $('.logos-carousel img').click(function(){
		var i = $(this).data('brand-number');
		$('.brands-carousel').slick('slickGoTo',i-1);
  });
  $('.carousel-news').slick({
	dots: false,
	 infinite: true,
	slidesToShow: 2,
	slidesToScroll: 2,
	prevArrow: '<a href="#"><img src="/img/prevgrey.svg" style="position:absolute; bottom: -67px; left:340px"></a>',
	nextArrow: '<a href="#"><img src="/img/nextgrey.svg" style="position:absolute; bottom: -67px; right:340px"></a>'
  });
  $('.carousel-faq').slick({
	dots: false,
	 infinite: true,
	slidesToShow: 2,
	slidesToScroll: 2,
	prevArrow: '<a href="#"><img src="/img/prevgrey.svg" style="position:absolute; bottom: -56px; left:340px"></a>',
	nextArrow: '<a href="#"><img src="/img/nextgrey.svg" style="position:absolute; bottom: -56px; right:340px"></a>'
  });
  $('.carousel-lots').slick({
	dots: false,
	prevArrow: '<a href="#"><img src="/img/prev.svg" class="lot-left-arrow"></a>',
	nextArrow: '<a href="#"><img src="/img/next.svg" class="lot-right-arrow"></a>'
  });
  $('.carousel-lots').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	$(this).parent('.main-foto').siblings('a.small-foto').removeClass( "selected");
	$(this).parent('.main-foto').siblings('a.small-foto').eq( nextSlide ).addClass( "selected");
  });
  $(document).on('click', '.lot-fotos .small-foto', function(){
	if($(this).siblings('.main-foto').children('.carousel-lots').length){
		var i = $(this).index('.lot-fotos .small-foto');
        try {
            $('.carousel-lots').slick('slickGoTo',i);
        }
        catch (error) {
            return;
        }
	}
  });
  if($().swipebox){
	$( '.swipebox' ).swipebox({loopAtEnd:true});
  }
});
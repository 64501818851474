$(function(){
	$(window).click(function(e) {
		if($(e.target).hasClass('modal') || $(e.target).hasClass('modal-close'))
			$('.modal').css('display','none');
	});

	$('.toggle-login').on('click', function(e) {
		e.preventDefault();
		$('#login-modal').css('display','block');
        $('#login-modal input#login').focus();
	});
});


//// textarea max 180 chars
$(function(){
	$('textarea.control-length').on('keyup change input paste',function(e){
		var $this = $(this);
		var val = $this.val();
		var valLength = val.length;
		var maxCount = $this.attr('maxlength');
		if(valLength>maxCount){
			$this.val($this.val().substring(0,maxCount));
		}
		$(this).siblings().find('.textarea-length').text(valLength);
	}); 
	$('textarea.control-length').trigger('keyup');
});

//// search for lot

$(function(){
	$( ".search-lot" ).focus(function(ev) {
		if($(ev.target).val()=='Поиск по лоту или владельцу...')
		  $(ev.target).val('');
	});
	$( ".default-value" ).focus(function(ev) {
		if($(ev.target).val()==$(ev.target).data('default'))
		  $(ev.target).val('');
	});
	$( ".default-value" ).focusout(function(ev) {
		if($(ev.target).val()=='')
		  $(ev.target).val($(ev.target).data('default'));
	});
});

//// dropdowns

$.fn.customDropdown = function() {
	var drp = this;
   this.find('li').click(function(ev){
		var val = $(this).children('span').size() ? $(this).children('span').eq(0).text() : $(this).text();  // first span will be returned, or simply text
		$(this).parents('ul').siblings('.first-child').children('span').text(val);
		var trg = $('#'+$(this).parents('.dropdown-container-row,.dropdown-container').data('target'));
		trg.val($(this).data('value'));
	});
	this.find('.first-child').click(function(ev){
		$('.dropdown-container ul,.dropdown-container-row ul').not($(this).siblings('ul')).removeClass('show');
		$(this).siblings('ul').toggleClass('show');
	});
	this.find('ul').mCustomScrollbar({
		theme: 'minimal-dark',
		scrollInertia: 0
	});
	this.find('li').click(function(ev){
		drp.trigger('onChange',this);
	});
	return this;
};

$(function(){
	$(window).click(function(event){
		if(!$(event.target).parents(".first-child").size()  && !$(event.target).hasClass('first-child')){
			$('.dropdown-container ul').removeClass('show');
			$('.dropdown-container-row ul').removeClass('show');
		}
	});

	function hasntParent(selector) {
		return function() {
			return $(this).parents(selector).length == 0;
		}
	}

	$('.dropdown-container,.dropdown-container-row')
		.not('.vue').filter(hasntParent('.vue'))
		.customDropdown().on('onChange',function(event,li){
		//console.log($(li).index());console.log(li);
	});
});

//// show/hide hidden blocks

$(function(){
	$( ".hidden-toggler" ).click(function(ev) {
		//$('#'+$(this).data('target')).toggleClass('show');
		$('.hidden').toggleClass('show');
		$( ".hidden-toggler" ).each(function(){
			var t = $.trim($(this).text());
			$(this).text($(this).data('new-caption'));
			$(this).data('new-caption', t);
		});
	});
});

//// check input
 
function checkInput(topOffset){ 
	var res = true;
	$('.check-error').each(function(){ 
		if(!$(this).val()){
			err.call(this,'Это поле обязательно для заполнения');
			return;
		}
		if($(this).hasClass('only-chars') && !/^[А-ЯЁа-яё\-A-Za-z]*$/.test($(this).val())){
			err.call(this,'Поле должно содержать только буквы');
			return;
		}
		if($(this).hasClass('check-email') && !validateEmail($(this).val())){
			err.call(this,'Некорректный e-mail');
			return;
		}
		$(this).siblings('.error-message').css('display','none');
		$(this).removeClass('error-input');
		
		function err(txt){ 
			res= false;
			$(this).siblings('.error-message').text(txt);
			$(this).siblings('.error-message').css('display','block');
			$(this).addClass('error-input');
		}
	});
	if(!res) 
		$(window).scrollTop(topOffset);
	return res;
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

//// mobile

function isMobile(){
	return ($(window).width() < parseInt($('main').css('max-width'), 10));
}

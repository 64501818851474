// MAIN MENU (categories)

function showCats(a, id, e){
	e.preventDefault();
	var cat = document.getElementById(id);
	if(!cat.classList.contains('show'))
		hideAllCats();
	cat.classList.toggle("show");
	if($(a).parents('.menu').size())
		cat.style.top = "160px";
	else
		cat.style.top = ($(window).scrollTop() + 10) +"px";
}
function hideAllCats(){
	var dropdowns = document.getElementsByClassName("menu-cats");
	var i;
	for (i = 0; i < dropdowns.length; i++) {
	  var openDropdown = dropdowns[i];
	  if (openDropdown.classList.contains('show')) 
		openDropdown.classList.remove('show');
	}
}
window.onclick = function(event) {
	if (!$(event.target).parents('.menu').size() && !$(event.target).parents('.menu-small').size() && !$(event.target).parents(".menu-cats").size()) 
		hideAllCats();
}
$(window).bind('scroll', function () {
	if ($(window).scrollTop() > 180) {
		$('.menu-shadow').addClass('show');
	} else {
		$('.menu-shadow').removeClass('show');
	}
	hideAllCats();
});

// PROFILE POPUP

$(function(){
	$("header .userpic, .menu-panel .userpic-small").click(function(e){
		$(".profile-popup").toggleClass("show");
		trg = $(e.target);
		if(trg.parents("header .userpic").size())
			$(".profile-popup").css('top','85px');
		else	
			$(".profile-popup").css('top',($(window).scrollTop() + 10) +"px");
	});
	$(window).click(function(event){
		if(!$(event.target).parents(".profile-popup").size() && !$(event.target).parents("header .userpic").size() && !$(event.target).parents(".menu-panel .userpic-small").size())
			$(".profile-popup").removeClass("show");
	});
	$(window).bind('scroll', function () {
		$(".profile-popup").removeClass("show");
	});
});

// FAV POPUP

$(function(){
    $("header .fav, .menu-panel .fav-small").click(function(e){
        $(".fav-popup").toggleClass("show");
        trg = $(e.target);
        if(trg.parents("header .fav").size())
            $(".fav-popup").css('top','85px');
        else
            $(".fav-popup").css('top',($(window).scrollTop() + 10) +"px");
    });
    $(window).click(function(event){
        if(!$(event.target).hasClass("fav-popup") && !$(event.target).parents(".fav-popup").size() && !$(event.target).parents("header .fav").size() && !$(event.target).parents(".menu-panel .fav-small").size() && !$(event.target).hasClass("favorite-popup-remove") )
            $(".fav-popup").removeClass("show");
    });
    $(window).bind('scroll', function () {
        $(".fav-popup").removeClass("show");
    });
});

// CITY CONFIRM & CHOOSE

$(function(){
	$(".city-confirm-yes,.city-confirm-no,.city-confirm-close").click(function(e){
		$('.city-confirm-popup').css('display','none');
	});
	$(".city-confirm-no").click(function(e){
		$('.city-choose-popup').css('display','block');
	});
	$(window).click(function(ev){
		var trg = $(ev.target);
		if((!trg.hasClass('city-choose-popup') && !trg.parents('.city-choose-popup').length && !trg.hasClass('city-confirm-no')) || trg.hasClass('city-choose-close'))
			//ev.preventDefault();
			$('.city-choose-popup').css('display','none');
	});
	$(".city-choose-popup .cities a").click(function(e){
		$('.city-choose-popup').css('display','none');
		$('.my-city').text($(this).text());
	});
	$("#choose-my-city").click(function(e){
		$(".city-confirm-no").trigger('click');
		e.preventDefault();
		e.stopPropagation();
	});
	$(".metro-container").mCustomScrollbar({
		theme: 'minimal-dark',
		scrollInertia: 0
	});
});

$( function() {
	$('.set-discount').click(function(event){
		$('.discount-popup').css('display','block');
		$('.discount-popup').css('top',event.pageY-30);
		$('.discount-popup').css('left',event.pageX-70);
	});
	$('#set,#cancel').click(function(event){
		$('.discount-popup').css('display','none');
	});
	$(window).click(function(event){
		if(!$(event.target).hasClass("set-discount") && !$(event.target).hasClass("discount-popup") && !$(event.target).parents(".discount-popup").size())
			$('.discount-popup').css('display','none');
	});
	$( "#slider" ).slider({
			  range: "min",
			  max: 355,
			  value: 134
	});
	$('#slider').slider().bind( "slidechange", function(e,ui  ) {
		var n = parseFloat($(".ui-slider-handle")[0].style.left);
		if(n<10) n=10;
		if(n>85) n=85;
		$('#curValue').css('left', n + "%");
		$('#curValue,#curValue2').text($( "#slider" ).slider( "value" ));
	} );
	$('#slider').trigger('slidechange');
});
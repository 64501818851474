//// datepickers

 $( function() {
	$( "#sort-date" ).datepicker({
		showOtherMonths: true,
		selectOtherMonths: true
	});
	$( "#sort-date-period1" ).datepicker({
		showOtherMonths: true,
		selectOtherMonths: true
	});
	$( "#sort-date-period2" ).datepicker({
		showOtherMonths: true,
		selectOtherMonths: true
	});
});

//// calendar popup

$(function(){
	$(document).on('click', ".toggle-calendar", function(ev){
		var date = $(ev.target).text();
		$(ev.target).addClass('date-in-process');
		$('.calendar-popup').css('left',ev.pageX-100);
		$('.calendar-popup').css('top',ev.pageY-100);
		$( "#datepicker" ).datepicker( "setDate", date );
		$('.calendar-popup').css('display','block');
	});
	$(window).on('click',function(event) {
		if(!$(event.target).parents(".calendar-popup").size()  && !$(event.target).hasClass('calendar-popup') && !$(event.target).hasClass('toggle-calendar')
			&& !$(event.target).parents(".ui-corner-all").size() && !$(event.target).hasClass('ui-corner-all')){
			$('.calendar-popup').css('display','none');
			$('.date-in-process').removeClass('date-in-process');
		}
	});
});

//// other popups

$(function(){
	$(document).on("click", ".sorting-link", function(ev){
		var trg = $(this).data('target');
		$('.sort-popup').removeClass('show');
		$('.'+trg).toggleClass('show');
	});
	$(".ui-datepicker").click(function(ev){
		ev.stopPropagation();
	});
	$(window).on('click',function(event) {
		if((!$(event.target).parents(".sort-popup").size()  && !$(event.target).hasClass('sort-popup') && !$(event.target).hasClass('sorting-link')) 
			|| $(event.target).hasClass('modal-close')){
			$('.sort-popup').removeClass('show');
		}
	});
	$('.sort-popup input[type=text]').focus(function(ev){
		$(this).parent().trigger('click');
	});
	$('label .cal-icon').click(function(ev){
		$(this).prev('input[type=text]').focus();;
	});
});
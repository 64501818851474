window.imageCropModal = function( $image, $inputBase64, $fileInput, $imageCrop, $modal )
{
    if( $inputBase64.val() )
        $image.attr( 'src', $inputBase64.val() );

    $fileInput.change( function ()
    {
        var fileReader = new FileReader(),
            files = this.files,
            file;

        if (!files.length) {
            return;
        }

        file = files[0];

        if (/^image\/\w+$/.test(file.type))
        {
            fileReader.readAsDataURL(file);
            fileReader.onload = function () {

                $modal.css('display', 'block');

                $fileInput.val("");

                $imageCrop.cropper({
                    aspectRatio: 1
                });

                $imageCrop.cropper("replace", this.result, true);

            };
        }

    });

    $modal.find('.modal-submit').click( function()
    {
        var blob = $imageCrop.cropper("getDataURL");

        $image.attr( 'src', blob );
        $inputBase64.val( blob );
        $imageCrop.cropper('destroy');

        $modal.find('.modal-close').click();
    });

};

var address = '';
var myMap;

//ymaps.ready(init);

$(function(){
	$(document).on('click', '.toggle-map', function(ev){
		if(myMap) myMap.destroy();
		address = $(ev.target).data('address');
		init();
		$('.map-popup').css('left',ev.pageX-150);
		$('.map-popup').css('top',ev.pageY-150);
		$('.map-popup #address').text(address);
		$('.map-popup #address-link').attr('href','https://yandex.ru/maps/?mode=search&text='+address);
		$('.map-popup').css('display','block');
	});
	
	$(window).click(function(event) {
		if((!$(event.target).parents(".map-popup").size()  && !$(event.target).hasClass('toggle-map') && !$(event.target).hasClass('map-popup')) || $(event.target).hasClass('modal-close'))
			$('.map-popup').css('display','none');
	});
});

function init() {
    myMap = new ymaps.Map('map', {
        center: [55.753994, 37.622093],
        zoom: 9,
		controls: ['smallMapDefaultSet']
    });

    ymaps.geocode(address, {
        results: 1
    }).then(function (res) {
            // Выбираем первый результат геокодирования.
            var firstGeoObject = res.geoObjects.get(0),
                // Координаты геообъекта.
                coords = firstGeoObject.geometry.getCoordinates(),
                // Область видимости геообъекта.
                bounds = firstGeoObject.properties.get('boundedBy');

            // Добавляем первый найденный геообъект на карту.
            myMap.geoObjects.add(firstGeoObject);
            // Масштабируем карту на область видимости геообъекта.
            myMap.setBounds(bounds, {
                // Проверяем наличие тайлов на данном масштабе.
                checkZoomRange: true
            });
        });
}